/**
 *
 */
export default defineNuxtRouteMiddleware(() => {
    const shopStore = useShopStore();
    const flowStore = useFlowStore();

    const valid = flowStore.productConfigUpsell?.key
        && shopStore.productMain?.config.upsells.includes(flowStore.productConfigUpsell?.key);

    if (!valid) {
        return navigateTo(flowStore.routeMainFlow);
    }
});
